<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="6"
          sm="4"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Type</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="typeFilter"
            :options="typeOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:typeFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          sm="4"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Dayborn</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="daybornFilter"
            :options="daybornOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:daybornFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          sm="4"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Payment</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="paymentFilter"
            :options="paymentOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:paymentFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          sm="4"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Service</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="serviceFilter"
            :options="serviceOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:serviceFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          sm="4"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Month</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="monthFilter"
            :options="monthOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:monthFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          sm="4"
          md="2"
          class="mb-md-0 mb-2"
        >
          <label>Status</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="statusFilter"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            @input="(val) => $emit('update:statusFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  props: {
    typeFilter: {
      type: [String, null],
      default: null,
    },
    daybornFilter: {
      type: [String, null],
      default: null,
    },
    paymentFilter: {
      type: [String, null],
      default: null,
    },
    serviceFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [String, null],
      default: null,
    },
    monthFilter: {
      type: [String, null],
      default: null,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    daybornOptions: {
      type: Array,
      required: true,
    },
    paymentOptions: {
      type: Array,
      required: true,
    },
    serviceOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    monthOptions: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
