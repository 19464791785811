import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'

export default function useReceiptListAddNew(props, clearForm, emit) {
  // ------------------------------------------------
  // receiptLocal
  // ------------------------------------------------
  const receiptLocal = ref(JSON.parse(JSON.stringify(props.receiptData.value)))
  const resetReceiptLocal = () => {
    receiptLocal.value = JSON.parse(JSON.stringify(props.receiptData.value))
  }
  watch(props.receiptData, () => {
    resetReceiptLocal()
  })

  // ------------------------------------------------
  // isAddNewReceiptSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isAddNewReceiptSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
      }, 350)
    }
  })
  

  const onSubmit = () => {
    const receiptData = JSON.parse(JSON.stringify(receiptLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.receiptData.value.id) emit('update-receipt', receiptData.value)
    else emit('add-receipt', receiptData.value)

    // Close sidebar
    // emit('update:is-add-new-receipt-sidebar-active', false)
  }


  /* eslint-enable global-require */

  return {
    receiptLocal,
    resetReceiptLocal,
    onSubmit,
  }
}
